import '../styles/pages/setup.scss'
import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import Header from "../elements/Header";
import DefaultInput from "../elements/inputs/DefaultInput";
import SubmitButton from "../elements/inputs/SubmitButton";
import BitrixUserSelector from "../elements/inputs/BitrixUserSelector";
import InlineNotification from "../elements/InlineNotification";
import OneSelectUI from "../elements/inputs/one-select";
import AllFieldsSelector from "../elements/fields-selector/AllFieldsSelector";
import Popup from "../elements/popup";
import s from "../styles/pages/main.module.scss";
import getAPIClientMocked from "../hooks/apiClientMocked";
import getAPIClient from "../hooks/apiClient";


export default function InstallPage({BX24}) {
    const [isLoading, setIsLoading] = useState(true)

    const api = useMemo(() => getAPIClient(BX24), [])

    // закомментировать, это мок
    //const api = useMemo(getAPIClientMocked, [])

    // Смарт процессы
    const [smartProcessesList, setSmartProcessesList] = useState([])
    useEffect(() => {
        api.get('/setup/smart_processes_from_portal').then(response => {
            let sp_list = []
            for (let i in response.data) {
                let sp = response.data[i]
                sp.label = sp.title
                sp.value = sp.local_smart_process_id
                sp.value = sp.local_smart_process_id
                sp_list.push(sp)
            }
            setSmartProcessesList(sp_list)
        }).finally(setIsLoading(false))
    }, [api])

    const [selectedSmartProcess, setSelectedSmartProcess] = useState(undefined)

    // Получение конфига, установка текущего СП
    useEffect(() => {
        if (smartProcessesList === undefined || smartProcessesList.length === 0)
            return

        api.get(`/bitrix_portals/settings`).then(response => {
            // Если настроек портала нет, то установка первая, а не повторная => не указываем текущий СП
            if (response.data === null || response.data === undefined)
                return

            let currentSP = JSON.parse(JSON.stringify(smartProcessesList.filter(
                (el) => response.data.local_smart_process_id === el.local_smart_process_id
            )[0]))
            setSelectedSmartProcess(currentSP)
        })
    }, [smartProcessesList])

    // Список отношений (данные -> поле)
    const [dataToFieldMapping, setDataToFieldMapping] = useState([])
    useEffect(() => {
        if (selectedSmartProcess === undefined) return
        setIsLoading(true)
        api.get(
            `/portal_fields_mappings?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}`
        ).then(response => {
            let mapping_list = []
            for (let i in response.data) {
                let mapping_item = response.data[i]
                mapping_item.label = mapping_item.ui_name
                mapping_item.value = mapping_item.system_name
                mapping_list.push(mapping_item)
            }
            setDataToFieldMapping(mapping_list)
        }).finally(() => setIsLoading(false))
    }, [selectedSmartProcess])


    // Список доступных полей на портале
    const [availableFieldsInSmartProcess, setAvailableFieldsInSmartProcess] = useState([])
    useEffect(() => {
        if (selectedSmartProcess === undefined) return
        api.get(
            `/setup/fields_smart_process?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}`
        ).then(response => {
            let fields_list = []
            for (let i in response.data) {
                let field = response.data[i]
                field.label = field.field_name
                field.value = field.b24_system_name
                fields_list.push(field)
            }
            setAvailableFieldsInSmartProcess(fields_list)
        })
    }, [selectedSmartProcess])

    // Если где-то в маппинге указано б24 поле, которого не существует в списке полей с бэка,
    // то удаляем это поле в маппинге
    const [wrongMappingClearedFlag, setWrongMappingClearedFlag] = useState(false)

    useEffect(() => {
        console.log('called', wrongMappingClearedFlag, dataToFieldMapping, availableFieldsInSmartProcess)

        if (dataToFieldMapping.length === 0 || availableFieldsInSmartProcess.length === 0) {
            return
        }
        if (wrongMappingClearedFlag) {
            return
        }
        setWrongMappingClearedFlag(true)

        let copiedDataToFieldMapping = JSON.parse(JSON.stringify(dataToFieldMapping))
        for (let i in copiedDataToFieldMapping) {
            let selectedField = availableFieldsInSmartProcess.filter(
                (field) => copiedDataToFieldMapping[i].b24_system_name === field.b24_system_name
            )[0]

            if (selectedField === undefined) {
                copiedDataToFieldMapping[i]['b24_system_name'] = null
            }
        }
        setDataToFieldMapping(copiedDataToFieldMapping)
    }, [dataToFieldMapping, availableFieldsInSmartProcess])



    // Флаг открытого попапа
    const [fieldsPopupSelectorOpenedFlag, setFieldsPopupSelectorOpenedFlag] = useState(false)

    // Счетчик для отображения на кнопке
    const currentConfiguredFieldsCounter = useMemo(() => {
        let counter = 0;
        for (let i in dataToFieldMapping) {
            if (dataToFieldMapping[i].b24_system_name !== null) {
                counter += 1
            }
        }
        return counter
    }, [dataToFieldMapping])

    // Функция установки приложения
    const installApplication = useCallback(async () => {
        setNotificationText('')
        setIsLoading(true)
        let data = {
            "smart_process_id": selectedSmartProcess.smart_process_id,
            "local_smart_process_id": selectedSmartProcess.local_smart_process_id,
            "smart_process_type": selectedSmartProcess.smart_process_type,
            "fields": dataToFieldMapping
        }
        try {
            await api.post(`/setup/install`, data)
            BX24.installFinish()
        } catch (e) {
            setIsLoading(false)
            setNotificationType('error')
            setNotificationText('Произошла непредвиденная ошибка. ' +
                'Вы можете сообщить о ней, нажав на кнопку "Чат поддержки"')
        }
    }, [selectedSmartProcess, dataToFieldMapping])

    const [notificationType, setNotificationType] = useState('error')
    const [notificationText, setNotificationText] = useState('')

    return <>
        <Loading isLoading={isLoading}/>
        <Header/>
        <div className={'center-main'}>
            <div className='setup-block setup-block-padding'>
                <InlineNotification
                    type={notificationType}
                    text={notificationText}
                    hideFlag={notificationText.length === 0}
                />
                <OneSelectUI
                    title={'Выберите, куда сохранить резюме'}
                    selectionOptions={smartProcessesList}
                    selectedOption={selectedSmartProcess}
                    setSelectedOption={setSelectedSmartProcess}
                    placeholder={'Выберите, куда будут попадать резюме'}
                />
                <div className={'title'}>
                    Выберите поля в этой сущности, в которые будут заноситься данные
                </div>
                <button disabled={selectedSmartProcess === undefined} className={'fields-selector-popup-button'} onClick={
                    () => setFieldsPopupSelectorOpenedFlag(true)
                }>
                    {currentConfiguredFieldsCounter === 0
                        ? 'Настроить сохраняемые данные'
                        : `${currentConfiguredFieldsCounter}/${dataToFieldMapping.length} Соотнесено данных`
                    }
                </button>
                <Popup openedFlag={fieldsPopupSelectorOpenedFlag} setOpenedFlag={setFieldsPopupSelectorOpenedFlag}>
                    {fieldsPopupSelectorOpenedFlag && <AllFieldsSelector
                        dataToFieldMapping={dataToFieldMapping}
                        setDataToFieldMapping={setDataToFieldMapping}
                        availableFieldsInSmartProcess={availableFieldsInSmartProcess}
                        comment={'Вы сможете настроить/изменить эти данные позже'}
                    />}
                </Popup>
                <SubmitButton
                    disabled={selectedSmartProcess === undefined}
                    onClick={installApplication}
                    text={'Установить приложение'}
                />
                <a className={'install_guide_link'} href={'https://www.youtube.com/watch?v=4bj2CbNHOX4&ab_channel=Вебформат'}>Как устанавливать приложение?</a>
            </div>
        </div>
    </>
}
