import '../../styles/elements/inputs/bitrix-user-selector.scss'

export default function BitrixUserSelector(
    {className = "", title, selectedUser, setSelectedUser, notSelectedText= 'Пользователь не выбран', BX24}
) {

    return (
        <div
            className={'user-selector ' + className}
            onClick={() => BX24.selectUser(setSelectedUser)}
        >
            <p className={'user-selector-title'}>{title}</p>
            <div className={'user-selector-main'}>
                <h1>{selectedUser?.name ? selectedUser.name : notSelectedText}</h1>
                <p>{selectedUser?.id ? 'ID: ' + selectedUser.id : ''}</p>
            </div>
        </div>
    )
}
